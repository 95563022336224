import {environmentVars} from './prod';
import {Client} from '../../../shared/utilities/type-helpers';
const client: Client = {clientType: 'claro', homePageFeedType: 'sessions'};

export const environment = {
  ...environmentVars,
  firebaseConfig: {
    apiKey: 'AIzaSyDjSoEzEaJi_eJmDI0Pi5QNDfDg0F039gY',
    authDomain: 'staging.searslive.com',
    databaseURL: 'https://sears-staging-default-rtdb.firebaseio.com',
    projectId: 'sears-staging',
    storageBucket: 'sears-staging.appspot.com',
    messagingSenderId: '615366377746',
    appId: '1:615366377746:web:2d51fdd2bf99267a4d73cb',
    measurementId: 'G-D6QKC44K0E',
  },
  traceOrigin: {
    name: 'sears-staging',
    domain: 'sears-staging',
  },
  client,
};
