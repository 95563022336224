import {DbSessionModel} from '../../../../shared/db-models/session';
import {DbStoreModel, DbStoreShippingMethodModel} from '../../../../shared/db-models/store';
import {DbProductImageModel, DbProductVariantModel} from '../../../../shared/db-models/product';
import {CustomTheme} from '../../../../shared/db-models/store';

export class ActiveStoreModel {
  id: string;
  name: string;
  logoUrl: string;
  productsLogoUrl: string;
  url: string;
  isManager: boolean;
  currency: string;
  fullData: DbStoreModel;
  theme: CustomTheme | undefined;
  sessionActionsRequireLoginDefault: boolean;
}

export class EditStoreModel extends DbStoreModel {
  shippingMethods: DbStoreShippingMethodModel[];

  // Client only
}

export class SessionModel extends DbSessionModel {
  // client only
  className?: string;
  state?: SessionState;
  isFull?: boolean;
  showFullCover: boolean;
  wasReminded: boolean;

  // Store homePage only
  isSharePopoverOpen: boolean;
  alreadyCheckedWasRemined: boolean;
}

export class CountriesCodes {
  name: string;
  dialCode: string;
  flag: string;
  isoCode: string;
}

export enum SessionState {
  CountDown = 1,
  Live = 2,
  Done = 3,
}

export class EditProductPhoto {
  image: DbProductImageModel;
  isNew: boolean;
  file?: File;
}

export class FileUpload {
  name: string;
  url: string;
  file: File;
}

export class EditProductVariant {
  data: DbProductVariantModel;
  imageIndexes: number[];
}

export type StoreFeatureFlagNames = 'enableShoppableVideo' | 'enableMarketing' | 'embedPostSession';

export type StoreFeatureFlags = {
  [key in StoreFeatureFlagNames]?: boolean;
};
