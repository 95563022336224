import {inject, Injectable} from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {Router} from '@angular/router';
import type {
  IAgoraRTC,
  IAgoraRTCClient,
  ICameraVideoTrack,
  ILocalVideoTrack,
  IMicrophoneAudioTrack,
  IRemoteVideoTrack,
  NetworkQuality,
  VideoPlayerConfig,
} from 'agora-rtc-sdk-ng';
import {lastValueFrom, Subscription, timer} from 'rxjs';
import {filter, map, retry} from 'rxjs/operators';
import {LogService} from 'src/app/logger/logger.service';
import {environment} from '../../../environments/environment';
import {LanguageService} from '../../language.service';
import {SessionService} from '../../session/shared/session.service';
import {AnalyticsService} from '../analytics.service';
import {AppService} from '../app.service';
import {StateHolderService} from '../state-holder.service';
import {inConnectionState} from './utils';
import {requestMediaPermissions} from './permission-checker';
import {StoresService} from '../stores.service';

const DEFAULT_BROADCAST_RESOLUTION = '720p_2';

let _agora: IAgoraRTC | undefined;
let _client: IAgoraRTCClient | undefined;
let _waiter: Promise<IAgoraRTC> | undefined;
let _networkQuality: NetworkQuality | undefined;
const AgoraRTC = async (): Promise<IAgoraRTC> => {
  if (!_agora) {
    const {default: Agora} = await import('agora-rtc-sdk-ng');
    _waiter =
      _waiter ??
      new Promise((resolve) => {
        _agora = Agora;
        _client = _agora.createClient({mode: 'live', codec: 'vp8', role: 'host'});
        _client.on('network-quality', (quality) => {
          _networkQuality = quality;
        });
        _agora.setLogLevel(2);
        console.trace('AgoraRTC initialized');
        resolve(_agora);
      });
    await _waiter;
  }
  return _agora!;
};

@Injectable({
  providedIn: 'root',
})
export class AgoraService {
  private fns = inject(AngularFireFunctions);
  protected router = inject(Router);
  private analytics = inject(AnalyticsService);
  private state = inject(StateHolderService);
  private appService = inject(AppService);
  private languageService = inject(LanguageService);
  private sessionService = inject(SessionService);
  private logService = inject(LogService);
  private storesService = inject(StoresService);

  public get client() {
    return _client;
  }

  public chosenCamera: MediaDeviceInfo | undefined;
  public chosenMicrophone?: MediaDeviceInfo | undefined;

  public recordingSid?: string;
  private broadcastToken?:
    | {
        broadcastToken: string;
        broadcastTokenUid: number;
        sessionId: string;
      }
    | undefined;

  public getClient() {
    const client = this.client;
    if (!client) return undefined;
    // if the host is streaming is means that he thinks he is connected
    // so in that case it doesn't matter if the connection state is not connected
    if (this.state.isAgoraPlayingVideo$.value) return client;
    if (!inConnectionState(client.connectionState)) return undefined;
    return this.client;
  }
  private async refreshPermissions() {
    try {
      return (await requestMediaPermissions()) === true;
    } catch (error) {
      return false;
    }
  }

  public async startScreenShare(canvasStream: MediaStreamTrack) {
    try {
      await AgoraRTC().then(async (AgoraRTC) => {
        const canvasTrack = AgoraRTC.createCustomVideoTrack({
          mediaStreamTrack: canvasStream,
          optimizationMode: 'motion',
        });
        const localVideoTrack = canvasTrack as ICameraVideoTrack;
        await this.client?.publish(localVideoTrack);
        this.play('agora-stream', localVideoTrack, {
          mirror: this.storesService.mirrorHostLocal,
        });
      });
    } catch (error) {
      this.logService.error('agora service ~ startScreenShare', error);
    }
  }

  private stop() {
    let toUnpublish: boolean | undefined;
    this.client?.localTracks.forEach((track) => {
      toUnpublish = toUnpublish || track.getStats().sendBytes !== 0;
      track.close();
    });
    return toUnpublish && this.client?.unpublish();
  }

  public async stopScreenShare() {
    try {
      await this.stop();
    } catch (error) {
      this.logService.error('agora service ~ stopScreenShare', error);
    }
  }

  public async getHostBroadcastToken(sessionId: string, autoJoin: boolean) {
    if (this.broadcastToken?.sessionId === sessionId && this.client?.channelName === sessionId) {
      return this.broadcastToken;
    }
    await this.client
      ?.leave()
      .catch((e) =>
        this.logService.error('agora service ~ getHostBroadcastToken ~ this.client?.leave()', e)
      );

    const [BroadcastTokenData, videoDevices, audioDevices] = await Promise.all([
      lastValueFrom(this.sessionService.getBroadcastToken(sessionId)),
      this.getVideoOutputs().catch(() => []),
      this.getAudioOutputs().catch(() => []),
    ]);
    this.chosenCamera = this.chosenCamera ?? videoDevices[0];
    this.chosenMicrophone = this.chosenMicrophone ?? audioDevices[0];

    if (autoJoin) {
      try {
        await this.client?.join(
          environment.agora.appId,
          sessionId,
          BroadcastTokenData.broadcastToken,
          BroadcastTokenData.broadcastTokenUid
        );
        await this.client?.renewToken(BroadcastTokenData.broadcastToken);
      } catch (e) {
        this.logService.error(
          'agora service ~ getHostBroadcastToken ~ this.client?.join() || this.client?.renewToken',
          e
        );
      }
    }
    this.broadcastToken = {...BroadcastTokenData, sessionId};
    return this.broadcastToken;
  }

  public async startBroadcast(sessionId: string, storeId: string) {
    const {broadcastToken, broadcastTokenUid} = await this.getHostBroadcastToken(sessionId, true);

    await AgoraRTC().then(async (AgoraRTC) => {
      try {
        if (!this.chosenMicrophone?.deviceId) {
          this.chosenMicrophone = (await this.getAudioOutputs())[0];
        }
        if (!this.chosenCamera?.deviceId) {
          this.chosenCamera = (await this.getVideoOutputs())[0];
        }
      } catch (_) {
        return;
      }
      const [localAudioTrack, localVideoTrack] = await Promise.all([
        AgoraRTC.createMicrophoneAudioTrack({
          encoderConfig: 'speech_standard',
          microphoneId: this.chosenMicrophone.deviceId,
        }),
        AgoraRTC.createCameraVideoTrack({
          encoderConfig:
            this.storesService.getActiveStoreSync()?.fullData.broadcastResolution ??
            DEFAULT_BROADCAST_RESOLUTION,
          optimizationMode: 'motion',
          cameraId: this.chosenCamera.deviceId,
        }),
      ]);

      this.play('agora-stream', localVideoTrack, {
        mirror: this.storesService.mirrorHostLocal,
      });
      await this.client?.unpublish();
      await this.client?.publish([localAudioTrack, localVideoTrack]);
      this.state.isAgoraPlayingVideo$.next(true);
      this.startAgoraCloudRecording(
        sessionId,
        broadcastToken,
        broadcastTokenUid,
        storeId
      ).subscribe({
        next: (res) => {
          // recordingSid saved for stopping the record later
          this.recordingSid = res.recordingSid;
        },
        error: (e) =>
          this.logService.error('agora service ~ startBroadcast ~ startAgoraCloudRecording', e),
      });
      this.state.iAmBroadcasting$.next(true);
    });

    return;
  }

  public async leaveChannel() {
    await this.client?.leave();
    this.analytics.logEvent('select_content', {
      path: this.router.url,
      user: this.client,
      content_type: 'leave_Channel',
    });
  }

  public async stopVideoBroadcast(sessionId: string) {
    try {
      await this.stop();
      await Promise.allSettled([
        lastValueFrom(this.stopAgoraCloudRecording(sessionId)),
        this.analytics.logEvent('select_content', {
          path: this.router.url,
          content_type: 'stop_broadcast',
        }),
        await this.getHostBroadcastToken(sessionId, false),
      ]);
    } catch (error) {
      this.logService.error('agora service ~ stopVideoBroadcast', error);
    } finally {
      this.state.isAgoraPlayingVideo$.next(false);
    }
  }

  /**
   * Starts agora cloud recording. Must supply the broadcastTokenUid that was
   * used to generate the broadcast token. (it is saved as a constant when created)
   *
   * @param sessionId
   * @param token
   * @param broadcastTokenUid
   * @param storeId
   */
  public startAgoraCloudRecording(
    sessionId: string,
    token: string,
    broadcastTokenUid: number,
    storeId: string
  ) {
    return this.fns
      .httpsCallable('startAgoraCloudRecording')({
        sessionId,
        token,
        hostUid: broadcastTokenUid,
        storeId,
      })
      .pipe(retry({count: 3, delay: 300, resetOnSuccess: true}));
  }

  /**
   * Stops agora cloud recording by calling Firebase function
   * Will work only if successful recording has started. otherwise - will return undefined
   *
   * @param sessionId
   */
  public stopAgoraCloudRecording(sessionId: string) {
    return this.fns
      .httpsCallable('stopAgoraCloudRecording')({
        sessionId,
        recordingSid: this.recordingSid,
      })
      .pipe(retry({count: 3, delay: 300, resetOnSuccess: true}));
  }

  public async startDemoBackstage() {
    // todo remove this and use the startPreview method
    await AgoraRTC().then(async (AgoraRTC) => {
      const localVideoTrack = await AgoraRTC.createCameraVideoTrack({
        encoderConfig: '720p_2',
        optimizationMode: 'motion',
      });
      this.play('agora-stream', localVideoTrack, {
        mirror: this.storesService.mirrorHostLocal,
      });
    });
  }

  public async startPreview() {
    const agora = await AgoraRTC();
    if (this.state.isAgoraPlayingVideo$.value || this.appService.isMobile.value) {
      return;
    }
    if (!this.chosenCamera) return;
    const localVideoTrack = await agora.createCameraVideoTrack({
      encoderConfig:
        this.storesService.getActiveStoreSync()?.fullData.broadcastResolution ??
        DEFAULT_BROADCAST_RESOLUTION,
      optimizationMode: 'motion',
      cameraId: this.chosenCamera.deviceId,
    });
    this.client?.localTracks
      .filter((track) => track.trackMediaType === 'video')
      .map(async (track) => {
        track?.isPlaying
          ? this.chosenCamera?.deviceId &&
            (await localVideoTrack.setDevice(this.chosenCamera?.deviceId))
          : this.play('agora-preview', localVideoTrack, {
              mirror: this.storesService.mirrorHostLocal,
            });
      });

    this.AutoStopPreview?.unsubscribe();
    this.AutoStopPreview = timer(10000).subscribe(() => this.closePreview());
  }

  private AutoStopPreview?: Subscription;

  public closePreview() {
    if (this.state.isAgoraPlayingVideo$.value) return;
    this.stop();
    this.AutoStopPreview = undefined;
  }

  public soundBackstage() {
    const previousVolume = -1;
    return timer(0, 100).pipe<number, number>(
      map(
        () =>
          this.client?.localTracks
            .find((t): t is IMicrophoneAudioTrack => t.trackMediaType === 'audio')
            ?.getVolumeLevel() ?? -1
      ),
      filter((currentVolume) => previousVolume !== currentVolume)
    );
  }

  public async getVideoOutputs(): Promise<MediaDeviceInfo[]> {
    this.refreshPermissions();
    try {
      const videoDevices = await (await AgoraRTC()).getCameras();
      return videoDevices;
    } catch (error) {
      console.error('agora service ~ getVideoOutputs', error);
      return [];
    }
  }

  public async getAudioOutputs(): Promise<MediaDeviceInfo[]> {
    this.refreshPermissions();
    try {
      const audioDevices = await (await AgoraRTC()).getMicrophones();
      return audioDevices;
    } catch (error) {
      console.error('agora service ~ getAudioOutputs', error);
      return [];
    }
  }

  public async switchVideoOutput(device: MediaDeviceInfo | undefined) {
    this.refreshPermissions();
    const previousDevice = this.chosenCamera;
    try {
      this.chosenCamera = device;
      if (device && this.state.isAgoraPlayingVideo$.value) {
        await this.appService.showConfirmationModal(
          this.languageService.translateSync('CONFIRM_MEDIA_DEVICE_SWITCHING.TITLE'),
          this.languageService.translateSync(
            `CONFIRM_MEDIA_DEVICE_SWITCHING.SUB_TITLE_BEFORE_DEVICE_NAME`
          ) +
            device.label +
            '" ' +
            this.languageService.translateSync(
              'CONFIRM_MEDIA_DEVICE_SWITCHING.SUB_TITLE_AFTER_DEVICE_NAME'
            ),
          this.languageService.translateSync(`CONFIRM_MEDIA_DEVICE_SWITCHING.CONFIRM_TXT`)
        );
      }
      this.chosenCamera = device;
      if (!device) return true;
      const tracks =
        this.client?.localTracks.filter((track) => track.trackMediaType === 'video') ?? [];
      const [track] = tracks;

      if (track?.isPlaying) {
        this.chosenCamera?.deviceId &&
          (await (track as ICameraVideoTrack).setDevice(this.chosenCamera?.deviceId));
      } else if (
        track &&
        !track.isPlaying &&
        this.client?.connectionState === 'CONNECTED' &&
        this.chosenCamera?.deviceId
      ) {
        await (track as ICameraVideoTrack).setDevice(this.chosenCamera?.deviceId);
      } else {
        tracks.forEach((track) => track?.close());
        this.play(
          'agora-preview',
          await (await AgoraRTC()).createCameraVideoTrack({cameraId: device.deviceId}),
          {
            mirror: this.storesService.mirrorHostLocal,
          }
        );
      }

      return true;
    } catch (error) {
      this.chosenCamera = previousDevice;
      return false;
    }
  }

  public async switchAudioOutput(device: MediaDeviceInfo | undefined) {
    const previousDevice = this.chosenMicrophone;
    try {
      this.chosenMicrophone = device;
      if (device && this.state.isAgoraPlayingVideo$.value) {
        await this.appService.showConfirmationModal(
          this.languageService.translateSync('CONFIRM_MEDIA_DEVICE_SWITCHING.TITLE'),
          this.languageService.translateSync(
            `CONFIRM_MEDIA_DEVICE_SWITCHING.SUB_TITLE_BEFORE_DEVICE_NAME`
          ) +
            device.label +
            '" ' +
            this.languageService.translateSync(
              'CONFIRM_MEDIA_DEVICE_SWITCHING.SUB_TITLE_AFTER_DEVICE_NAME'
            ),
          this.languageService.translateSync(`CONFIRM_MEDIA_DEVICE_SWITCHING.CONFIRM_TXT`)
        );
      }
      this.chosenMicrophone = device;

      this.client?.localTracks
        .filter((track) => track.trackMediaType === 'audio')
        .map(async (track) => {
          track?.isPlaying &&
            this.chosenCamera?.deviceId &&
            (await (track as ICameraVideoTrack).setDevice(this.chosenCamera?.deviceId));
        });
      return true;
    } catch (error) {
      this.chosenMicrophone = previousDevice;
      return false;
    }
  }

  private play(
    divId: string,
    videoTrack: ILocalVideoTrack | IRemoteVideoTrack | undefined,
    options?: VideoPlayerConfig
  ) {
    let count = 0;
    const interval = setInterval(() => {
      if (!videoTrack) {
        clearInterval(interval);
        return;
      }
      if (count++ > 150) {
        // 15 seconds
        clearInterval(interval);
        return;
      }
      const elm = document.getElementById(divId);
      if (elm === null) return;
      elm.innerHTML = '';
      videoTrack.play(divId, options);
      clearInterval(interval);
    }, 100);
  }
}
