// terrific & live
import {generalVars} from './general';

export const environmentVars = {
  ...generalVars,
  allowImageExt: '.jpg,.png,.jpeg',
  maxUplodedImageSize: 5000001,
  production: true,
  stripePublishKey:
    'pk_live_51Iuiu6FXQqlOoXxhnvrp6yGulbj5S7krEr7rP8NrTM4BwblU02Vk2c3A3nsaCp4sSfucMVTjWajs9ADE0Gi3ETNc00S5nwrCgr',
  useEmulators: false,
  featureFlags: {
    coHosts: false,
    flickeringElementsFlag_Like: true,
    flickeringElementsFlag_cart: true,
    flickeringElementsFlag_featuredItem: true,
    flickeringElementsFlag_poll: true,
    integrationsForEcommerce: false,
    longPolling: false,
    screenShare: false,
    vod: true,
    backstage: false,
    storeManageAnalytics: false,
  },
  agora: {
    appId: '6bc227bdda1d47eda35749fe6a6605ec',
    //  * - 0: DEBUG. Output all API logs.
    //  * - 1: INFO. Output logs of the INFO, WARNING and ERROR level.
    //  * - 2: WARNING. Output logs of the WARNING and ERROR level.
    //  * - 3: ERROR. Output logs of the ERROR level.
    //  * - 4: NONE. Do not output any log.
    logLevel: 4,
  },
  intercom: {
    appId: 'afpdoxqb',
  },
  shopifyAppUrl: 'https://partners.shopify.com/2615331/apps/8735621121/test',
  logLevel: 3,
  typesenseCollectionNames: {
    products: 'live-products',
  },
};
